<template>
  <div class="w-100 d-flex flex-column">
    <base-form class="d-flex flex-column mr-md-3 mt-3" @click="updateAffaire" :loading="loading" shadow>
      <base-form-group title="Informations" dense icon="check-square">
        <span class="text-muted">
          <small> Veuillez indiquer ici les informations relatives à votre commande. </small>
        </span>
        <base-form-row class="mb-2">
          <base-inputDatePicker
            v-model="affaire_date_debut"
            inputText="Date de début (démarrage commande)"
            :errors="feedback.affaire_date_debut"
          ></base-inputDatePicker>
        </base-form-row>
        <base-form-row class="mb-2">
          <base-inputDatePicker
            v-model="affaire_date_fin"
            inputText="Date de fin (échéance commande)"
            :errors="feedback.affaire_date_fin"
          ></base-inputDatePicker>
        </base-form-row>
        <base-form-row class="mb-2">
          <base-input-number
            v-model="affaire_commande_montant"
            inputText="Montant de la commande"
            :errors="feedback.affaire_commande_montant"
          ></base-input-number>
        </base-form-row>
        <base-form-row class="mb-2">
          <base-input-area
            v-model="affaire_commande_commentaire"
            text="Commentaires"
            :errors="feedback.affaire_commande_commentaire"
          ></base-input-area>
        </base-form-row>
        <base-form-row class="mb-2">
          <base-input-area
            v-model="affaire_commande_adresse"
            text="Emetteur de la commande (adresse)"
            :errors="feedback.affaire_commande_adresse"
          ></base-input-area>
        </base-form-row>
        <base-form-row class="mb-5">
          <base-input-area
            v-model="affaire_commande_adresse_facturation"
            text="Etablissement à facturer (adresse)"
            :errors="feedback.affaire_commande_adresse_facturation"
          ></base-input-area>
        </base-form-row>
        <div class="w-100">
          <uploader
            v-if="id"
            id="document_affaire_prepa"
            url="/upload/document"
            title="Déposer vos fichiers"
            :file_system="'CRM'"
            :file_field="'commande'"
            :file_key="id"
          ></uploader>
        </div>
      </base-form-group>
    </base-form>
  </div>
</template>
<script>
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormGroup from "@/components/bases/FormGroup.vue";
import Uploader from "@/components/bases/Uploader.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseInputArea from "@/components/bases/InputArea.vue";
import BaseInputDatePicker from "@/components/bases/InputDatePicker.vue";
import BaseInputNumber from "@/components/bases/InputNumber.vue";

export default {
  components: {
    BaseForm,
    BaseFormGroup,
    Uploader,
    BaseFormRow,
    BaseInputArea,
    BaseInputDatePicker,
    BaseInputNumber,
  },
  data() {
    return {
      loading: false,
      lists: {},
      feedback: {},
      test: "",
    };
  },
  computed: {
    ...mapFields("affaires", [
      "affaire.id",
      "affaire.affaire_date_debut",
      "affaire.affaire_date_fin",
      "affaire.affaire_commande_montant",
      "affaire.affaire_commande_adresse",
      "affaire.affaire_commande_adresse_facturation",
      "affaire.affaire_commande_commentaire",
    ]),
    bg() {
      return this.$store.getters["colors/colors"].color2;
    },
  },
  methods: {
    ...mapActions({
      update: "affaires/updateAffaire",
    }),
    updateAffaire: function () {
      this.loading = true;
      this.feedback = {};
      this.update()
        .catch((error) => this.errHandler(error))
        .finally(() => (this.loading = false));
    },
    errHandler: function (error) {
      if (error.response.status === 422) {
        this.feedback = error.response.data.error;
        return;
      }
    },
    hideQuiz: function () {
      this.$refs.questionnaireAtpSelect.hideModal();
    },
  },
};
</script>
